<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-row>
        <v-col sm="2"><div id="lbl-screen-name">P一覧</div></v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <!-- 検索項目 -->

      <v-row>
        <v-col cols="12" />
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="1">
              <div class="search-label">
                {{ $t("label.lbl_supplier") }}<span class="require">*</span>
              </div>
            </v-col>
            <v-col cols="2">
              <v-text-field
                id="txt-supplier"
                class="txt-single"
                v-model="suppliersSelected"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <div class="search-label">入荷予定No</div>
            </v-col>
            <v-col cols="2">
              <v-text-field
                id="txt-arrivalScheduleNo"
                class="txt-single"
                v-model="arrivalScheduleNo"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="listData"
        fixed-header
        :headers="headerItems"
        :items="inputList"
        disable-pagination
        disable-sort
        :hide-default-footer="true"
        height="500px"
      >
        <template v-slot:item.productCd="{ item }">
          <div>{{ item.productCd }}</div>
          <div>{{ item.productNm }}</div>
        </template>
        <template v-slot:item.accInfos="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="text-truncate" style="max-width: 200px" v-bind="attrs" v-on="on">
                {{ item.accInfos }}
              </div>
            </template>
            <span>{{ item.accInfos }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
    />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";

// import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import { i18n } from "../../lang/lang.js";
//import { appConfig } from "../../assets/scripts/js/AppConfig";
//import { dateTimeHelper } from "../assets/scripts/js/DateTimeHelper";
//import { messsageUtil } from "../assets/scripts/js/MesssageUtil";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../../assets/scripts/js/ScreenAuth";
export default {
  name: "PListPopup",
  components: {
    Loading,
    SimpleDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
    },
    //loadingCounter: 0,
    //roles: "",
    suppliersSelected: "取引先1",
    arrivalScheduleNo: "入荷先1",

    //deliveryTime: "",
    //operationType: "",
    //infoDialog: {
    //isOpen: false,
    //title: "",
    //message: "",
    //},
    //isOpenPackCompletion: false,
    //isOpenLoadCompletion: false,
    //isOpenPackDeadlineUpdate: false,
    //isOpenLoadDeadlineUpdate: false,
    //deadlineTime: "",
    //validationResult: false,
    //message: {
    //"P-MNG-002_001_C": messsageUtil.getMessage("P-MNG-002_001_C"),
    //"P-MNG-002_003_C": messsageUtil.getMessage("P-MNG-002_003_C"),
    //"P-MNG-002_004_C": messsageUtil.getMessage("P-MNG-002_004_C"),
    //},

    headerItems: [
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "productNm",
        width: "10%",
        align: "left",
        sortable: false,
      },
      {
        text: "製品付帯",
        value: "productAcc",
        width: "10%",
        align: "left",
        sortable: false,
      },
      { text: "入荷予定数", value: "productQty", width: "10%", align: "right", sortable: false },
      { text: "ケース（荷姿）数", value: "caseSu", width: "10%", align: "right" },
      { text: "ボール（中箱）数", value: "ballSu", width: "10%", align: "right" },
      { text: "バラ（端数）", value: "baraSu", width: "10%", align: "right" },
      { text: "ロット", value: "lot", width: "10%", align: "left" },
      { text: "賞味期限", value: "expireDate", width: "5%", align: "left" },
      { text: "付帯情報", value: "accInfos", width: "5%", align: "left" },
      { text: "格納予定ロケーション登録", value: "location", width: "20%", align: "center" },
    ],
    inputList: [
      {
        productNm: "製品名",
        productCd: "a",
        productAcc: "製品付帯",
        productQty: "0",
        caseSu: "0",
        ballSu: "0",
        baraSu: "0",
        lot: "lot",
        expireDate: "2022/01/13",
      },
    ],
    dummy_input: {
      productNm: "製品名",
      productCd: "a",
      productAcc: "製品付帯",
      productQty: "0",
      caseSu: "0",
      ballSu: "0",
      baraSu: "0",
      lot: "lot",
      expireDate: "2022/01/13",
    },
    defaultItem: {
      productNm: "",
      productAcc: "製品付帯",
      productQty: "0",
      caseSu: "0",
      ballSu: "0",
      baraSu: "0",
      lot: "lot",
      expireDate: "2022/01/13",
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    makeDummy() {
      this.inputList = [];
      this.inputList.push({
        productNm: "製品名",
        productCd: "製品コード",
        productAcc: "製品付帯",
        productQty: "0",
        caseSu: "0",
        ballSu: "0",
        baraSu: "0",
        lot: "ロット番号",
        expireDateCal: this.formatDate(
          this.dateCalc(dateTimeHelper.convertJstDateTime(new Date()))
        ),
        expireDate: this.formatDate(this.dateCalc(dateTimeHelper.convertJstDateTime(new Date()))),
      });
    },
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.makeDummy();
      //this.arrivalScheduleNo = this.entity.txt_arrivalScheduleNo.text;
      //this.suppliersSelected = this.entity.suppliersSelected.text;
      //this.txt_arrivalScheduleNo = "";
      //this.txt_slipNo = "";
      //screenAuth.canAccess();
    },

    computed: {},
    watch: {
      /*
      // eslint-disable-next-line no-unused-vars
      isOpenDetailDialog: function (newValue, oldValue) {
        console.info("ProgressDetail");
        if (newValue) {
          this.init();
        }
      },
      */
    },
    mounted() {
      //this.roles = sessionStorage.getItem("role");
      this.init();
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.list-single ::v-deep,
.txt-single ::v-deep {
  padding-top: 0;
  padding-right: 0;
  font-size: large;
}
</style>
