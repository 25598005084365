<template>
  <div style="background: white" id="aa">
    <!-- <HeaderBar /> -->
    <v-sheet class="overflow-hidden">
      <Loading />
      <v-container fluid id="sub-bar">
        <v-row>
          <v-col cols="11">
            <v-btn text @click="showMenu">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
            <div id="lbl-screen-name">検索</div>
          </v-col>
          <v-col cols="1">
            <v-btn text id="btn-logout" class="menu-btn" @click="logout">
              <img src="../../assets/img/logout_menu_icon.png" />ログアウト</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <!-- 検索項目-->
      <v-container fluid>
        <v-row>
          <v-col cols="12" />
        </v-row>
        <v-row>
          <v-col cols="2">
            <!-- 取引先-->
            <v-row>
              <v-col cols="11">
                <v-autocomplete
                  dense
                  v-model="suppliersSelected"
                  :items="dummy_supplierList"
                  :label="$t('label.lbl_supplier')"
                  :hint="setSrhSupplier()"
                  class="txt-single"
                  persistent-hint
                ></v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-row><span class="require">*</span> </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="2">
            <!-- 入荷元-->
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="arrivalSelected"
                  :items="dummy_arrivalList"
                  :label="$t('label.lbl_arrival')"
                  :hint="setSrhArrival()"
                  class="txt-single"
                  persistent-hint
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <!-- 荷主伝票番号-->
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="txt_slipNo"
                  maxlength="50"
                  :label="$t('label.lbl_slipNo')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  clearable
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <!-- 入荷ステータス-->
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="arrivalStatusSelected"
                  :items="dummy_arrivalStatusList"
                  :label="$t('label.lbl_arrivalStatus')"
                  :hint="setStsArrival()"
                  class="txt-single"
                  persistent-hint
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <!-- 入荷予定日付-->
            <v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  v-model="arrivalScheduleDate"
                  :label="$t('label.lbl_arrivalScheduleDate')"
                  @change="changeDate"
                  :rules="[rules.yyyymmdd]"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-row><span class="require">*</span> </v-row>
              </v-col>
              <v-col cols="5">
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text><v-icon>mdi-calendar</v-icon></v-btn>
                  </template>
                  <v-date-picker
                    v-model="arrivalScheduleDateCal"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
                <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text>
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1">
            <!-- 有償/無償-->
            <v-row>
              <v-col cols="12">
                <v-btn-toggle v-model="isPaid" color="blue darken-2" class="toggle black--text">
                  <v-btn id="btn-pack" class="paid-btn" value="1" small>
                    {{ $t("btn.btn_paid") }}
                  </v-btn>
                  <v-btn id="btn-load" class="paid-btn" value="2" small>
                    {{ $t("btn.btn_free") }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="6">
                      <v-btn color="primary" id="btn-search" class="api-btn" @click="search">{{
                        $t("btn.btn_search")
                      }}</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="7" />
                    <v-col cols="2">
                      <div class="search-label">{{ $t("label.lbl_itemsPerPage") }}</div>
                    </v-col>
                    <v-col cols="1">
                      <v-btn-toggle
                        v-model="itemsPerPage"
                        color="blue darken-2"
                        class="toggle black--text"
                        right
                      >
                        <v-btn class="pasing-btn" value="10" small>
                          {{ $t("10") }}
                        </v-btn>
                        <v-btn class="pasing-btn" value="50" small>
                          {{ $t("50") }}
                        </v-btn>
                        <v-btn class="pasing-btn" value="100" small>
                          {{ $t("100") }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
      <!-- データテーブル（項目） -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          disable-filtering
          disable-sort
          :hide-default-footer="true"
          height="655px"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.productNm`]="{ item }">
            <v-btn color="blue darken-1" text @click="openPopup(items)">
              {{ item.productNm }}
            </v-btn>
          </template>
          <template v-slot:item.accInfos="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-truncate" style="max-width: 200px" v-bind="attrs" v-on="on">
                  {{ item.accInfos }}
                </div>
              </template>
              <span>{{ item.accInfos }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.location="{ item }">
            <v-btn small @click="addLocation(item)" text> <v-icon>mdi-pencil</v-icon> </v-btn>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
      />
      <!-- <v-dialog v-model="dialogLocation" :max-width="800">
        <LocationDialog
          :isOpenDetailDialog.sync="dialogLocation"
          v-on:setLocation="saveLocation"
          :locInfo="locInfo"
        />
      </v-dialog> -->
      <!-- 進捗情報詳細モーダル-->
      <v-dialog v-model="pListPopup">
        <PListPopup :isOpenDetailDialog.sync="pListPopup" :entity="detailDialogData" />
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import PListPopup from "./PListPopup";
//有馬さん対応後、コメント外す
//import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
//有馬さん対応後、コメント外す
//import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";
// import HeaderBar from "@/components/HeaderBar.vue";
export default {
  name: "listPopup",
  components: {
    // HeaderBar,
    Loading,
    SimpleDialog,
    sideMenu,
    PListPopup,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    pListPopup: false,
    warehouse: { warehouse: "", location: "", productCd: "", qty: "" },
    locInfo: { locProductCd: "", warehouse: "", location: "", productCd: "", qty: 0, init: false },
    //isOpenDetailDialog: false,
    // ロケーションダイアログ
    dialogLocation: false,
    // 入荷予定日付
    arrivalScheduleDateCal: "",
    expireDateCal: "",
    arrivalScheduleDate: "",
    // 荷主伝票番号
    txt_slipNo: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: "10",
    // 有償/無償
    isPaid: "1",
    // 入荷先
    arrivalSelected: "all",
    // 取引先
    suppliersSelected: "all",
    // メニュー
    openMenu: null,
    // 入荷予定No.
    txt_arrivalScheduleNo: "",
    // 入荷予定日付キャレンダー
    dateMenu: false,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    // ヘッダ
    headerItems: [
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "productNm",
        width: "10%",
        sortable: false,
        align: "left",
      },
      {
        text: "製品付帯",
        value: "productAcc",
        width: "10%",
        sortable: false,
      },
      { text: "入荷予定数", value: "productQty", width: "10%", align: "right", sortable: false },
      { text: "ケース（荷姿）数", value: "caseSu", align: "right", width: "10%" },
      { text: "ボール（中箱）数", value: "ballSu", align: "right", width: "10%" },
      { text: "バラ（端数）", value: "baraSu", align: "right", width: "10%" },
      { text: "ロット", value: "lot", width: "10%" },
      { text: "賞味期限", value: "expireDate", width: "5%" },
      { text: "付帯情報", value: "accInfos", width: "5%" },
      { text: "格納予定ロケーション登録", value: "location", align: "center", width: "20%" },
    ],
    detailDialogData: {},
    inputList: [],

    dummy_supplierList: [
      { text: "", value: "" },
      {
        text: "supplier1（取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先1）",
        value: "supplier1",
        name: "取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先1",
      },
      { text: "supplier2（取引先2）", value: "supplier2", name: "取引先2" },
      { text: "supplier3（取引先3）", value: "supplier3", name: "取引先3" },
      { text: "supplier4（取引先4）", value: "supplier4", name: "取引先4" },
      { text: "supplier5（取引先5）", value: "supplier5", name: "取引先5" },
    ],
    dummy_arrivalList: [
      { text: "", value: "" },
      { text: "arrival1（入荷先1）", value: "arrival1", name: "入荷先1" },
      { text: "arrival2（入荷先2）", value: "arrival2", name: "入荷先2" },
      { text: "arrival3（入荷先3）", value: "arrival3", name: "入荷先3" },
      { text: "arrival4（入荷先4）", value: "arrival4", name: "入荷先4" },
      { text: "arrival5（入荷先5）", value: "arrival5", name: "入荷先5" },
    ],
    dummy_arrivalStatusList: [
      { text: "", value: "" },
      { text: "status1（ステータス1）", value: "arrival1", name: "入荷" },
      { text: "status2（ステータス2）", value: "arrival2", name: "良品" },
    ],
    dummy_input: {
      productNm: "製品名",
      productCd: "a",
      productAcc: "製品付帯",
      productQty: "0",
      caseSu: "0",
      ballSu: "0",
      baraSu: "0",
      lot: "lot",
      expireDate: "2022/01/13",
    },
    defaultItem: {
      productNm: "",
      productAcc: "製品付帯",
      productQty: "0",
      caseSu: "0",
      ballSu: "0",
      baraSu: "0",
      lot: "lot",
      expireDate: "2022/01/13",
    },
    productList: [
      { text: "", value: "" },
      { text: "新製品", value: "product0" },
      { text: "製品1", value: "product1" },
      { text: "製品2", value: "product2" },
      { text: "製品3", value: "product3" },
      { text: "製品4", value: "product4" },
    ],

    rules: {
      yyyymmdd: (value) =>
        dateTimeHelper.validDate(commonUtil.zen2han(value), "/") || "日付が間違っています",
    },
  }),
  methods: {
    // 検索ボータン
    search() {
      for (var i = 0; i < 100; i++) {
        var accInfos = [];
        var data = {
          productNm: "productNm" + i,
          productCd: "productCd" + i,
          productAcc: "productAcc" + i,
          productQty: i,
          caseSu: i,
          ballSu: i * 10,
          baraSu: i * 100,
          lot: "lot" + i,
          expireDate: "expireDate",
          accInfo01: "info01_" + i,
          accInfo02: "info02_" + i,
          accInfo03: "info03_" + i,
          accInfo04: "info04_" + i,
          accInfo05: "info05_" + i,
          accInfo06: "info06_" + i,
          accInfo07: "info07_" + i,
          accInfo08: "info08_" + i,
          accInfo09: "info09_" + i,
          accInfo10: "info10_" + i,
          accInfo11: "info11_" + i,
          accInfo12: "info12_" + i,
          accInfo13: "info13_" + i,
          accInfo14: "info14_" + i,
          accInfo15: "info15_" + i,
          accInfo16: "info16_" + i,
          accInfo17: "info17_" + i,
          accInfo18: "info18_" + i,
          accInfo19: "info19_" + i,
          accInfo20: "info20_" + i,
          accInfos: "",
        };
        for (var j = 1; j <= 20; j++) {
          var idx = j < 10 ? "0" + j : j;
          accInfos.push(data["accInfo" + idx]);
        }
        data.accInfos = accInfos.join("\n");
        this.inputList.push(data);
      }
    },
    openPopup() {
      this.pListPopup = true;
    },
    // 初期化
    init() {
      this.arrivalSelected = "all";
      this.suppliersSelected = "all";
      this.txt_arrivalScheduleNo = "";
      this.txt_slipNo = "";
      this.inputList = [];
      this.arrivalScheduleDateCal = this.dateCalc(dateTimeHelper.convertJstDateTime(new Date()));
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 入荷予定登録画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.dummy_supplierList.length; i++) {
        if (this.dummy_supplierList[i].value == this.suppliersSelected) {
          return this.dummy_supplierList[i].name;
        }
      }
    },
    /**
     * 入荷予定登録画面：入荷元コードリストボックス取得処理
     */
    setSrhArrival() {
      for (var i = 0; i < this.dummy_arrivalList.length; i++) {
        if (this.dummy_arrivalList[i].value == this.arrivalSelected) {
          return this.dummy_arrivalList[i].name;
        }
      }
    },
    /**
     * 入荷予定登録画面：入荷ステータスリストボックス取得処理
     */
    setStsArrival() {
      for (var i = 0; i < this.dummy_arrivalStatusList.length; i++) {
        if (this.dummy_arrivalStatusList[i].value == this.arrivalStatusSelected) {
          return this.dummy_arrivalStatusList[i].name;
        }
      }
    },
    /**
     * 与えられた日付から年月日を文字列で返します。
     */
    dateCalc(date) {
      const dt = new Date(date);
      return dt.toISOString().substr(0, 10);
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },
    /**
     * 進捗情報詳細画面を開きます。
     */
    openDetailDialog(dummy_input, targetSupplierCode, targetArrivalScheduleNo) {
      console.info("openDetailDialog");
      //      screenAuth.canAccess();
      // 詳細画面へ渡す値を設定します。
      this.detailDialogData = dummy_input;
      // 選択された列名
      this.detailDialogData.suppliersSelected = targetSupplierCode;

      //this.detailDialogData.selectSalesOfficeCode = item;
      //this.detailDialogData.deliveryDestAddressCode = targetDelivery;
      this.detailDialogData.txt_arrivalScheduleNo = targetArrivalScheduleNo;

      this.isOpenDetailDialog = true;
    },
    // ロケーション登録
    addLocation(item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.dummy_input = Object.assign({}, item);
      if (this.dummy_input.warehouse == undefined) {
        this.locInfo.warehouse = "";
        this.locInfo.location = "";
        this.locInfo.productCd = "";
        this.locInfo.qty = 0;
        this.locInfo.init = true;
      } else {
        this.locInfo.warehouse = this.dummy_input.warehouse.warehouse;
        this.locInfo.location = this.dummy_input.warehouse.location;
        this.locInfo.productCd = this.dummy_input.warehouse.productCd;
        this.locInfo.qty = this.dummy_input.warehouse.qty;
        this.locInfo.init = false;
      }
      this.locInfo.locProductCd = this.dummy_input.productCd;
      this.dialogLocation = true;
    },
    // ロケーション保存
    saveLocation(data) {
      this.dummy_input.warehouse = data;
      Object.assign(this.inputList[this.editedIndex], this.dummy_input);

      this.dialogLocation = false;
    },
    changeDate() {
      if (this.arrivalScheduleDate.length == 8) {
        var dt = this.arrivalScheduleDate;
        this.arrivalScheduleDate = dt.substr(0, 4) + "/" + dt.substr(4, 2) + "/" + dt.substr(6, 2);
      }
      dateTimeHelper.validDate(this.arrivalScheduleDate);
    },
    formValidation() {
      this.$refs.searchForm.validate();
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    arrivalScheduleDateCal(val) {
      this.arrivalScheduleDate = this.formatDate(val);
    },
  },

  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
//新製品登録時、色変更
.isD {
  background-color: $color-warning !important;
  //background-color: $color-error !important;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
</style>
